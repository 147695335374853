<template>
    <span>
        <v-slide-x-transition group>
            <span v-if="prefix != null" :class="spanClass" key="a">{{ prefix }}</span>
            <span v-if="isNegated" key="b">-</span>
            <span v-for="(increment, index) in filteredIncrements" :key="index" :class="spanClass">
                {{ increment.value | toDisplayNumber }}{{ increment.text }}{{ abbreviation ? '' : increment.value != 0 ? 's' : '' }}
            </span>
        </v-slide-x-transition>
        <slot name="append" />
    </span>
</template>

<script>
import { firstBy } from 'thenby';

export default {
    name: 'BT-Increment-Quantity',
    data: function() {
        return {
            increments: null,
            isLoading: false
        }
    },
    props: {
        abbreviation: {
            type: Boolean,
            default: true
        },
        hideZero: {
            type: Boolean,
            default: true
        },
        measurements: {
            type: Array,
            default: null
        },
        prefix: {
            type: String,
            default: null
        },
        productID: {
            type: String,
            default: null
        },
        spanClass: {
            type: String,
            default: null
        },
        successValue: {
            type: Number,
            default: null
        },
        value: null //the raw quantity
    },
    computed: {
        filteredIncrements() {
            if (isNaN(this.value)) {
                return [];
            }

            var iList = [];
            var remaining = this.value >= 0 ? this.value : 0 - this.value;

            if (this.isLengthyArray(this.increments) && this.isLengthyArray(this.measurements)) {
                var l = this.increments.filter(y => this.measurements.some(m => m.id == y.measurementID) && y.productID == this.productID);

                l.sort(firstBy(x => x.units, 'desc'));

                l.forEach(increment => {
                    if (increment.units <= remaining) {
                        var m = this.measurements.find(y => y.id == increment.measurementID);
                        if (m != null) {
                            var tRemaining = (remaining % increment.units);
                            
                            iList.push({
                                text: this.abbreviation ? m.abbreviation : m.measurementName,
                                value: (remaining - tRemaining) / increment.units,
                                unitValue: (this.value - remaining)
                            })

                            remaining = tRemaining;
                        }
                    }
                })
            }
            
            if (remaining > 0) {
                if (iList.length > 0) {
                    iList.push({
                        text: this.abbreviation ? 'u' : 'Unit',
                        value: remaining
                    })
                }
                else {
                    iList.push({
                        text: null,
                        value: remaining
                    })
                }
            }
            else if (!this.hideZero && iList.length == 0) {
                iList.push({
                    text: null,
                    value: 0
                })
            }

            return iList;
        },
        isNegated() {
            return this.value != null && this.value < 0;
        }
    },
    async mounted() {
        if (this.increments == null) {
            try {
                this.isLoading = true;
                this.$forceUpdate();

                this.increments = await this.$BlitzIt.store.getAll('stock-increments');
            }
            finally {
                this.isLoading = false;
            }
        }
    }
}
</script>